import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type {
  MessagePageOrReplyPageAndParams,
  MessageParamPages,
  MessageReplyPageParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef, useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getMessageRouteAndParams } from '../../../helpers/messages/MessageHelper/MessageHelper';
import type {
  MessageLinkFragment,
  SearchMessageEventMutation,
  SearchMessageEventMutationVariables
} from '../../../types/graphql-types';
import searchMessageEvent from '../MessageListForSearch/SearchMessageEvent.mutation.graphql';
import MessageSearchContext from '../MessageListForSearch/MessageSearchContext';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';

interface Props {
  /**
   * The message to create the link for.
   */
  message: MessageLinkFragment;
  /**
   * The wrapped element.
   */
  children?: React.ReactNode;
  /**
   * Whether to pass the href to the children component, only needed when children component is not an `<a>`.
   * This parameter is passed directly to the underlying `next/Link` component.
   *
   * See more here: https://nextjs.org/docs/api-reference/next/link
   */
  passHref?: boolean;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * If the message is to be redirected to draft view instead of published.
   */
  isDraftView?: boolean;
  /**
   * If the link needs to be opened in a new tab.
   */
  isNewTab?: boolean;
  /**
   * Query to be added in Url
   */
  query?: ParsedUrlQuery;
  /**
   * Tab index of the element
   */
  tabIndex?: number;
  /**
   * The aria-label for message link.
   */
  ariaLabel?: string;
  /**
   * Whether to use manual sort navigation.
   */
  useManualSortNav?: boolean;
}

type MessagePageParams = MessageParamPages | MessageReplyPageParams;

/**
 * Creates a NextJS link for a message.
 *
 * @author Adam Ayres
 */
const MessageLink: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLAnchorElement>
> = forwardRef(function MessageLinkForward(
  {
    message,
    children,
    passHref = false,
    className,
    as = 'a',
    isDraftView = false,
    isNewTab = false,
    query,
    tabIndex,
    ariaLabel,
    useManualSortNav = false
  },
  ref
) {
  const cx = useClassNameMapper();
  const { Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const Component = as;
  const searchEventTrackingIdMap = useContext(MessageSearchContext);

  const { route, params } = getMessageRouteAndParams(message);
  let queryParam = query ?? null;

  if (isDraftView) {
    queryParam = { previewMessage: 'true' };
  }

  if (useManualSortNav) {
    queryParam = { ...queryParam, [EndUserQueryParams.SHOW_MANUAL_SORT_NAV]: 'true' };
  }

  const finalParams =
    params &&
    Object.fromEntries(Object.keys(params).map(key => [key, getCaseSensitivePath(params[key])]));

  const [searchMessageEventCall] = useMutationWithTracing<
    SearchMessageEventMutation,
    SearchMessageEventMutationVariables
  >(module, searchMessageEvent);

  return (
    <Link<MessagePageOrReplyPageAndParams>
      route={route}
      params={finalParams as unknown as MessagePageParams}
      passHref={passHref}
      query={queryParam !== null && queryParam}
      legacyBehavior={true}
    >
      <Component
        className={cx(className)}
        data-testid="MessageLink"
        target={isNewTab ? '_blank' : '_self'}
        tabIndex={tabIndex}
        ref={ref}
        aria-label={ariaLabel || message.subject}
        onClick={async () => {
          const searchEventTrackingId = searchEventTrackingIdMap
            ? searchEventTrackingIdMap[message.id]
            : null;
          if (searchEventTrackingId) {
            await searchMessageEventCall({
              variables: {
                messageId: message.id,
                eventTrackingID: searchEventTrackingId
              }
            });
          }
        }}
        suppressHydrationWarning
      >
        {children}
      </Component>
    </Link>
  );
});

export default MessageLink;
