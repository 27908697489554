import type { AttachmentConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import placeholderPageInfo from './placeholderPageInfo';

function getDefaultAttachmentConnection(): Required<AttachmentConnection> {
  return {
    __typename: 'AttachmentConnection',
    totalCount: 0,
    edges: [],
    pageInfo: placeholderPageInfo()
  };
}

export default function placeholderAttachmentConnection(
  attachmentConnection: Partial<AttachmentConnection> = {},
  useDefaults = true
): AttachmentConnection {
  return {
    ...(useDefaults
      ? getDefaultAttachmentConnection()
      : { pageInfo: undefined, totalCount: 0, edges: undefined }),
    ...attachmentConnection
  };
}
