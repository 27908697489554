import { createContext } from 'react';

/**
 * Creating context to store the information related to search events which we retrieve while rendering message link
 */
export interface MessageSearchContextInterface {
  searchEventTrackingIdMap?: Map<string, string>;
}

export default createContext<MessageSearchContextInterface>(null);
