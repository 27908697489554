import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageTimeToReadFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The message to display the time to read value for.
   */
  message: MessageTimeToReadFragment;
}

/**
 * Displays the time to read a message, in minutes.
 *
 * @author Sravan Reddy
 */
const MessageTimeToRead: React.FC<React.PropsWithChildren<Props>> = ({
  as: Component = 'h5',
  className,
  message
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_TIME_TO_READ
  );

  if (textLoading) {
    return null;
  }

  return (
    <Component className={cx(className)}>
      {formatMessage('minReadText', { min: message?.timeToRead > 0 ? message?.timeToRead : 1 })}
    </Component>
  );
};

export default MessageTimeToRead;
