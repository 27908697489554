import type { PageInfo } from '@aurora/shared-generated/types/graphql-schema-types';

function getDefaultPageInfo(): Required<PageInfo> {
  return {
    __typename: 'PageInfo',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null
  };
}

export default function placeholderPageInfo(
  pageInfo: Partial<PageInfo> = {},
  useDefaults = true
): PageInfo {
  return {
    hasNextPage: false,
    hasPreviousPage: false,
    ...(useDefaults ? getDefaultPageInfo() : {}),
    ...pageInfo
  };
}
