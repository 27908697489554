import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import type { MessageSolvedBadgeFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './MessageSolvedBadge.module.css';

interface Props {
  /**
   * The message to display the badge for.
   */
  message: MessageSolvedBadgeFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * A badge representing if the message is part of a solved conversation.
 * @author Martin Barotto
 */
const MessageSolvedBadge: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_SOLVED_BADGE
  );

  if (textLoading) {
    return null;
  }

  const { conversation, depth } = message;
  return (
    (!depth || depth === 0) &&
    conversation?.solved === true && (
      <Badge pill className={cx('lia-solved-badge', className)} data-testid="messageSolvedBadge">
        {formatMessage('solved')}
      </Badge>
    )
  );
};

export default MessageSolvedBadge;
