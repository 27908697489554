import type { RevisionConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import placeholderPageInfo from './placeholderPageInfo';

function getDefaultRevisionConnection(
  revisionConnection: Partial<RevisionConnection> = {}
): Required<RevisionConnection> {
  return {
    __typename: 'RevisionConnection',
    totalCount: 0,
    pageInfo: placeholderPageInfo(),
    edges: [],
    ...revisionConnection
  };
}

export default function placeholderRevisionConnection(
  revisionConnection: Partial<RevisionConnection> = {},
  useDefaults = true
): RevisionConnection {
  return {
    ...(useDefaults
      ? getDefaultRevisionConnection()
      : { pageInfo: undefined, totalCount: 0, edges: [] }),
    ...revisionConnection
  };
}
