import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import type {
  AssociatedImage,
  Attachment
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { AssociatedImageFragment } from '../../../types/graphql-types';

/**
 * Gets the unique image id given it's url.
 *
 * @param url Image url
 */
export function getImageIdFromUrl(url: string): string {
  const result = url?.match(/^[^#?]*?\/images\/+([^\s"#'/?]+)/);
  return result?.length > 1 ? result[1] : null;
}

/**
 * A data structure which contains all the necessary image information.
 *
 * @author Prashanth Rangarajan
 */
class ImageOption {
  /**
   * Unique ID of the image.
   */
  id: string;

  /**
   * Image url.
   */
  url: string;

  /**
   * Download url of the image
   */
  downloadUrl: string;

  /**
   * Title of the image.
   */
  title: string;

  /**
   * Alternative text for the image
   */
  alt: string;

  /**
   * Width of the image
   */
  width?: number;

  /**
   * Height of the image
   */
  height?: number;

  /**
   * Constructor to initialise `ImageOption` data structure using either the `AssociatedImage` or image `Attachment`
   *
   * @constructor
   * @param image `AssociatedImage` or image `Attachment` data
   */
  constructor(
    image:
      | Pick<
          AssociatedImageFragment,
          'url' | 'title' | 'altText' | 'width' | 'height' | '__typename'
        >
      | Pick<Attachment, 'id' | 'url' | 'filename' | '__typename'>
  ) {
    const { __typename } = image;
    switch (__typename) {
      case 'AssociatedImage': {
        const { url, title, altText, width, height } = image as AssociatedImage;
        this.id = getImageIdFromUrl(url);
        this.url = url;
        this.downloadUrl = UrlBuilder.fromUrl(url).addQuery({ download: 'true' }).build();
        this.title = title;
        this.alt = altText ?? title;
        this.width = width;
        this.height = height;
        break;
      }
      case 'Attachment': {
        const { id, url, filename } = image as Attachment;
        this.id = id;
        this.url = url;
        this.downloadUrl = url;
        this.title = filename;
        this.alt = filename;
        break;
      }
      default:
    }
  }
}

export default ImageOption;
