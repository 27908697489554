import type { MessageConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import placeholderPageInfo from './placeholderPageInfo';

function getDefaultMessageConnection(
  messageConnection: Partial<MessageConnection> = {}
): Required<MessageConnection> {
  return {
    __typename: 'MessageConnection',
    totalCount: 0,
    pageInfo: placeholderPageInfo(),
    edges: [],
    ...messageConnection
  };
}

export default function placeholderMessageConnection(
  messageConnection: Partial<MessageConnection> = {},
  useDefaults = true
): MessageConnection {
  return {
    ...(useDefaults
      ? getDefaultMessageConnection()
      : { pageInfo: undefined, totalCount: 0, edges: undefined }),
    ...messageConnection
  };
}
