import type { IdeaStatusDetails } from '@aurora/shared-generated/types/graphql-schema-types';
import { IdeaStatusLabelType } from '@aurora/shared-generated/types/graphql-schema-types';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MessageStatusLabel.module.pcss';

interface Props {
  /**
   * The status of the idea.
   */
  status: IdeaStatusDetails;
  /**
   * CSS classname to be applied
   */
  className?: string;
}

/**
 * Renders an styled label for status of the message.
 *
 * @author Abhijith.Be
 */
const MessageStatusLabel: React.FC<React.PropsWithChildren<Props>> = ({ status, className }) => {
  const cx = useClassNameMapper(localStyles);

  if (!status) {
    return null;
  }

  const { text, label } = status;
  const { type, color } = label;
  const isBoxed = label.type !== IdeaStatusLabelType.Textonly;

  return (
    <div className={cx('lia-status', className)}>
      <span
        style={{
          borderColor: IdeaStatusLabelType.Outline === type ? `#${color}99` : `#${color}`,
          color: `#${color}`,
          background: IdeaStatusLabelType.Filled === type ? `#${color}` : ''
        }}
        className={cx(
          { 'lia-is-boxed': isBoxed },
          `lia-status-label lia-is-${type?.toLowerCase()}`
        )}
      >
        <span className={cx('text-break lia-g-clamp')}>{text}</span>
      </span>
    </div>
  );
};

export default MessageStatusLabel;
