import type { RatingConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import placeholderPageInfo from './placeholderPageInfo';

function getDefaultRatingConnection(): Required<RatingConnection> {
  return {
    __typename: 'RatingConnection',
    totalCount: 0,
    edges: [],
    pageInfo: placeholderPageInfo()
  };
}

export default function placeholderRatingConnection(
  ratingConnection: Partial<RatingConnection> = {},
  useDefaults = true
): RatingConnection {
  return {
    ...(useDefaults
      ? getDefaultRatingConnection()
      : { pageInfo: undefined, totalCount: 0, edges: undefined }),
    ...ratingConnection
  };
}
