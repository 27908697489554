import { canUseDOM } from 'exenv';

/**
 * The gist response
 */
type GistResponse = {
  div: string;
  stylesheet: string;
};

const GIST_REGEX = /<script.*?src="(https:\/\/gist.github.com\/.*?\/(.*?).js)"><\/script>/g;

/**
 * Add script to head
 * @param source source url of script
 */
function addScript(source: string): void {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', source);
  document.head.append(script);
}

/**
 * Add styles to head, checks if it already exists
 * and only adds it once.
 * @param url url of stylesheet
 */
function addStylesOnce(url: string): void {
  if (
    ![...document.head.querySelectorAll('link')].some(
      element => element.getAttribute('href') === url
    )
  ) {
    const link = document.createElement('link');
    link.setAttribute('type', 'text/css');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', url);
    document.head.append(link);
  }
}

/**
 * Update the contents of the placeholder divs that are added in place of
 * the gist script tags.
 * @param gistUid unique id for gist
 * @param gistResponse Gist JSON response
 */
function addGistContentToPage(gistUid: string, gistResponse: GistResponse): void {
  const { div, stylesheet } = gistResponse;
  addStylesOnce(stylesheet);

  const elements = document.querySelectorAll(`[data-gist="${gistUid}"]`);
  [...elements].forEach(element => {
    element.innerHTML = div;
  });
}

/**
 * Manages adding script and style elements for a gist.
 * @param gistUid unique id for gist
 * @param url Gist url
 */
function processGistScript(gistUid: string, url: string): void {
  const callbackId = `gist${gistUid}`;
  const jsonCallbackUrl = `${url}on?callback=${callbackId}`;
  const callbackResponseId = `${callbackId}Response`;
  const storedResponse: GistResponse = window[callbackResponseId];

  // If the gist response has already been returned, use that instead.
  if (storedResponse) {
    addGistContentToPage(gistUid, storedResponse);
  } else {
    addScript(jsonCallbackUrl);

    window[callbackId] = (response: GistResponse) => {
      window[callbackResponseId] = response;
      addGistContentToPage(gistUid, response);
    };
  }
}

/**
 * Uses regex to match a script element and captures the script URL (group1)
 * and the gist uid (group2).
 *
 * https://regex101.com/r/6GtwmG/1
 * @param content html content
 */
export function replaceGistScripts(content: string) {
  return content.replaceAll(GIST_REGEX, (_match, scriptUrl, gistUid) => {
    if (canUseDOM) {
      processGistScript(gistUid, scriptUrl);
    }
    return `<div data-gist="${gistUid}" class="lia-embeded-content"></div>`;
  });
}
