import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import { mediaDimensionsFromIconSize } from '../../../helpers/images/MediaHelper';
import Avatar from '../../common/Avatar/Avatar';
import { IconSize } from '../../common/Icon/enums';
import useTranslation from '../../useTranslation';
import localStyles from './UserAvatar.module.pcss';
import type { UserAvatarProps } from './UserAvatarProps';

/**
 * Display a user's avatar.
 *
 * @author Dolan Halbrook, Adam Ayres
 */
const UserAvatar: React.FC<React.PropsWithChildren<UserAvatarProps>> = ({
  user,
  className,
  size = IconSize.PX_50,
  ariaHidden = false
}): JSX.Element => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.USER_AVATAR);

  if (textLoading) {
    return null;
  }

  const originalHref = user?.avatar?.url;
  const dimensions = mediaDimensionsFromIconSize(size);

  /**
   * We can clean this code up if we remove the old avatar at the database level and leave the fields blank, todo
   */
  const imageUrl =
    originalHref && !originalHref.includes('icon_anonymous_profile')
      ? UrlHelper.adjustImageServerPageUrl(originalHref, dimensions)
      : null;

  return (
    <span className={cx(`lia-avatar lia-g-icon-size-${size}`, className)} data-testid="userAvatar">
      {/* todo this should probably use a ShapedAvatar, check with UX */}
      <Avatar
        alt={
          user === null
            ? formatMessage('altTextGeneric')
            : formatMessage('altText', { login: user?.login })
        }
        className={cx('lia-avatar-img')}
        href={imageUrl}
        ariaHidden={ariaHidden}
      />
    </span>
  );
};

export default UserAvatar;
