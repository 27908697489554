import type { RevisionsDiffResponse } from '@aurora/shared-generated/types/graphql-schema-types';
import placeholderRevisionsDiff from './placeholderRevisionsDiff';

export function getDefaultRevisionsDiffResponse(): Required<RevisionsDiffResponse> {
  return {
    __typename: 'RevisionsDiffResponse',
    errors: null,
    result: placeholderRevisionsDiff()
  };
}

export default function placeholderRevisionsDiffResponse(
  revisionsDiffResponse: Partial<RevisionsDiffResponse> = {},
  useDefaults = true
): RevisionsDiffResponse {
  return {
    ...(useDefaults ? getDefaultRevisionsDiffResponse() : {}),
    ...revisionsDiffResponse
  };
}
