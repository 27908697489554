import type { RevisionsDiff } from '@aurora/shared-generated/types/graphql-schema-types';

export function getDefaultRevisionsDiff(): Required<RevisionsDiff> {
  return {
    __typename: 'RevisionsDiff',
    body: null,
    introduction: null,
    messageIdRef: null,
    sourceRevisionIdRef: null,
    subject: null,
    targetRevisionIdRef: null,
    teaser: null
  };
}

export default function placeholderRevisionsDiff(
  revisionsDiff: Partial<RevisionsDiff> = {},
  useDefaults = true
): RevisionsDiff {
  return {
    ...(useDefaults ? getDefaultRevisionsDiff() : {}),
    ...revisionsDiff
  };
}
