import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import TenantContext from '../../context/TenantContext';
import useCrossOriginAttribute from '../../useCrossOriginAttribute';
import type AvatarProps from './AvatarProps';

/**
 * The most basic building block for other Avatar-based components
 * @author Rosalyn Rowe
 */
const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  alt,
  className,
  href,
  ariaHidden = false
}) => {
  const tenant = useContext(TenantContext);
  const crossOrigin = useCrossOriginAttribute();
  const cx = useClassNameMapper();
  let avatarHref = href || '/static/avatars/avatar-user-unknown.svg';

  // add url base path to default avatars
  if (avatarHref.startsWith('/static')) {
    avatarHref = UrlHelper.prefixBasePath(tenant, avatarHref);
  }

  return (
    <img
      src={avatarHref}
      alt={ariaHidden ? '' : alt}
      className={cx(className)}
      aria-hidden={ariaHidden}
      crossOrigin={crossOrigin(avatarHref)}
    />
  );
};

export default Avatar;
