import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

/**
 * Scans for preformatted code samples from a provided body element and applies text highlighting.
 * @param body body element where code samples are located.
 * @param className class name to add to each code sample.
 */
export default function highlightCodeSamples(body: Element, className: string): void {
  const codeSampleElements = body.querySelectorAll('pre.lia-code-sample');
  if (codeSampleElements.length > 0) {
    import('../editor/PrismHelper')
      .then(() => {
        codeSampleElements.forEach(codeSampleElement => {
          codeSampleElement.classList.add(className, 'line-numbers');
          global.Prism.highlightAll();
        });
        return true;
      })
      .catch(error => {
        log.warn(error, 'Unable to load PrismHelper.  Code highlighting may be affected.');
      });
  }
}
