import type { Message, Scalars } from '@aurora/shared-generated/types/graphql-schema-types';
import { EntityType, VisibilityScope } from '@aurora/shared-generated/types/graphql-schema-types';
import type { Tenant } from '@aurora/shared-types/tenant';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import placeholderAttachmentConnection from './placeholderAttachmentConnection';
import placeholderMessageConnection from './placeholderMessageConnection';
import placeholderRatingConnection from './placeholderRatingConnection';
import placeholderRevisionConnection from './placeholderRevisionConnection';
import placeholderRevisionsDiffResponse from './placeholderRevisionsDiffResponse';

function getMessageRequiredFields(tenant: Tenant, id: Scalars['ID']['output']): Message {
  return {
    author: null,
    attachments: null,
    board: null,
    conversation: null,
    currentRevision: null,
    descendants: placeholderMessageConnection(),
    depth: 0,
    hasGivenKudo: false,
    kudosSumWeight: 0,
    entityType: EntityType.ForumTopic,
    id,
    language: null,
    messagePolicies: null,
    metrics: null,
    postTime: null,
    postTimeFriendly: null,
    readOnly: false,
    replies: placeholderMessageConnection(),
    revisionNum: 0,
    subject: null,
    timeToRead: 0,
    uid: Number.parseInt(IdConverter.decodeIdAsParts(tenant, id).id, 10),
    repliesCount: 0,
    visibilityScope: VisibilityScope.Public,
    editFrozen: false,
    body: null,
    rawBody: null,
    read: false,
    revisions: placeholderRevisionConnection(),
    plusOnes: null,
    ratings: placeholderRatingConnection(),
    customFields: [],
    customFormFieldsForUpdate: [],
    hasCustomFormFieldsSet: false,
    viewHref: '/'
  };
}

function getDefaultMessage(tenant: Tenant, id: Scalars['ID']['output']): Required<Message> {
  return {
    ...getMessageRequiredFields(tenant, id),
    attachments: placeholderAttachmentConnection(),
    body: null,
    images: null,
    kudos: undefined,
    kudosSumWeight: 0,
    plusOne: false,
    placeholder: false,
    editFrozen: false,
    read: false,
    searchSnippet: null,
    rawBody: null,
    metrics: null,
    lastPublishTime: null,
    revisionNum: 0,
    originalMessageForPlaceholder: null,
    moderationData: null,
    abuseReports: null,
    visibilityScope: VisibilityScope.Public,
    latestVersion: null,
    latestRevision: null,
    revisionsDiff: placeholderRevisionsDiffResponse(),
    videos: null,
    customField: () => null,
    viewHref: '/'
  };
}

export default function placeholderMessage(
  tenant: Tenant,
  message: Partial<Message> = {},
  useDefaults = true
): Message {
  let id;
  if (message?.id) {
    id = message?.id;
  } else {
    const uid = message?.uid ?? `${Date.now()}|temp`;
    id = IdConverter.encodeId(tenant, { type: 'message', id: `${uid}` });
  }
  return {
    id,
    ...(useDefaults ? getDefaultMessage(tenant, id) : getMessageRequiredFields(tenant, id)),
    ...message
  };
}
