import useCrossOriginAttribute from '@aurora/shared-client/components/useCrossOriginAttribute';
import { ImageAssociationType } from '@aurora/shared-generated/types/graphql-schema-types';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React from 'react';
import { Image } from 'react-bootstrap';
import type {
  AssociatedImageFragment,
  MessagePreviewImageFragment
} from '../../../types/graphql-types';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The message the images belong to.
   */
  message: MessagePreviewImageFragment;
  /**
   * The fallback component to be rendered when the message has no associated images.
   */
  fallback?: React.ComponentType<React.PropsWithChildren<unknown>>;
  /**
   *  Determines the order of priority for preview images(from lowest to highest).
   */
  associationRanks?: ImageAssociationType[];
}

/**
 * A single preview image for a message.
 *
 * @author Jonathan Bridges
 */
const MessagePreviewImage: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  fallback: FallbackComponent = null,
  associationRanks = [
    ImageAssociationType.Body,
    ImageAssociationType.Cover,
    ImageAssociationType.Teaser
  ]
}) => {
  const crossOrigin = useCrossOriginAttribute();
  const images = message?.images?.edges;

  let previewImage: AssociatedImageFragment = null;
  images?.forEach(image => {
    const { associationType, width, height } = image.node;
    const isMinimumSize = width >= 100 || height >= 100;
    if (associationRanks.includes(associationType)) {
      if (!previewImage && isMinimumSize) {
        previewImage = image.node;
      } else if (
        previewImage &&
        associationRanks.indexOf(associationType) >
          associationRanks.indexOf(previewImage.associationType) &&
        isMinimumSize
      ) {
        previewImage = image.node;
      }
    }
  });

  const finalImageUrl = previewImage?.url
    ? UrlHelper.adjustImageServerPageUrl(previewImage?.url, { width: 300, height: 400 }, true)
    : null;

  return previewImage ? (
    <Image
      className={className}
      src={finalImageUrl}
      alt={previewImage?.altText}
      data-testid="messagePreviewImage"
      crossOrigin={crossOrigin(finalImageUrl)}
    />
  ) : FallbackComponent ? (
    <FallbackComponent />
  ) : null;
};

export default MessagePreviewImage;
